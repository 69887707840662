import {BigNumber, Contract} from "ethers";

export async function getPendingTokens(contract: Contract, stakedNfts: BigNumber[]) : Promise<number> {
    let pendingErc20Tokens = 0;
    try {
        const promises = stakedNfts.map(async (nftId) => {
            const pendingTokens = await contract.calculateTokens(nftId);
            pendingErc20Tokens += pendingTokens.toNumber();
        });
        await Promise.all(promises);
    } catch (e) {
        console.log("Error loading pending tokens: ", e)
    }
    return pendingErc20Tokens
}