import {ethers, ContractInterface, utils, Signer} from "ethers";
import { JsonRpcProvider } from "@ethersproject/providers";

import bridgeEthAbi from "./abis/bridgeEth.json";
import bridgePolyAbi from "./abis/bridgePoly.json";
import gameAbi from "./abis/game.json";
import stakingEthAbi from "./abis/staker-eth.json";
import stakingPolyAbi from "./abis/staker-poly.json";
import tokenEthAbi from "./abis/token-eth.json";
import tokenPolyAbi from "./abis/token-poly.json";
import wildcardAbi from "./abis/wildcard.json";
import erc20Abi from "./abis/erc20.json";
import {Biconomy} from "@biconomy/mexa";

const {
    REACT_APP_ETH_PROVIDER,
    REACT_APP_POLY_PROVIDER,
    REACT_APP_TEST_ETH_PROVIDER,
    REACT_APP_TEST_POLY_PROVIDER,
    REACT_APP_NETWORK_ID_ETH,
    REACT_APP_NETWORK_ID_POLY,
    REACT_APP_TEST_NETWORK_ID_ETH,
    REACT_APP_TEST_NETWORK_ID_POLY,
    REACT_APP_TEST_CONTRACT_BRIDGE_ETH,
    REACT_APP_TEST_CONTRACT_BRIDGE_POLY,
    REACT_APP_TEST_CONTRACT_GAME,
    REACT_APP_TEST_CONTRACT_STAKING_ETH,
    REACT_APP_TEST_CONTRACT_STAKING_POLY,
    REACT_APP_TEST_CONTRACT_TOKEN_ETH,
    REACT_APP_TEST_CONTRACT_TOKEN_POLY,
    REACT_APP_TEST_CONTRACT_WILDCARD,
    REACT_APP_TEST_CONTRACT_ERC20_TOKEN,
    REACT_APP_CONTRACT_BRIDGE_ETH,
    REACT_APP_CONTRACT_BRIDGE_POLY,
    REACT_APP_CONTRACT_GAME,
    REACT_APP_CONTRACT_STAKING_ETH,
    REACT_APP_CONTRACT_STAKING_POLY,
    REACT_APP_CONTRACT_TOKEN_ETH,
    REACT_APP_CONTRACT_TOKEN_POLY,
    REACT_APP_CONTRACT_WILDCARD,
    REACT_APP_CONTRACT_ERC20_TOKEN
} = process.env;

const isTesting = process.env.REACT_APP_IS_TESTING === "true";

export enum ContractType {
    BridgeEth,
    BridgePoly,
    Game,
    StakingEth,
    StakingPoly,
    TokenEth,
    TokenPoly,
    WildCard,
    ERC20Token
}

export enum ProviderType {
    "eth",
    "poly"
}

export const signature_message = "HouseHaeds!\n" +
    "\n" +
    "This request will not trigger a blockchain transaction or cost any gas fees.\n" +
    "\n" +
    "Wallet address:";

export async function getContract (contract : ContractType, biconomy: Biconomy | null = null) {
    const contractData = getContractData(contract);
    const signer = biconomy?.ethersProvider || await (getProvider().getSigner());
    return new ethers.Contract(contractData.address, contractData.abi, signer);
}

export function getReadOnlyContract (contract : ContractType) {
    const contractData = getContractData(contract);
    const url = ContractType[contract].toString().includes("Eth") ?
        isTesting ? REACT_APP_TEST_ETH_PROVIDER : REACT_APP_ETH_PROVIDER :
        isTesting ? REACT_APP_TEST_POLY_PROVIDER : REACT_APP_POLY_PROVIDER;
    const provider = new ethers.providers.JsonRpcProvider(url, "any");
    return new ethers.Contract(contractData.address, contractData.abi, provider);
}

export function getChainedProvider (providerType: ProviderType) {
    const url = providerType === ProviderType.eth ?
        isTesting ? REACT_APP_TEST_ETH_PROVIDER : REACT_APP_ETH_PROVIDER :
        isTesting ? REACT_APP_TEST_POLY_PROVIDER : REACT_APP_POLY_PROVIDER;
    return new JsonRpcProvider(url, "any");
}

export function initBiconomy () {
    return new Biconomy(window.ethereum, {
        apiKey: process.env.REACT_APP_BICONOMY_API_KEY!,
        debug: true,
        contractAddresses: [
            isTesting ? REACT_APP_TEST_CONTRACT_BRIDGE_POLY : REACT_APP_CONTRACT_BRIDGE_POLY,
            isTesting ? REACT_APP_TEST_CONTRACT_TOKEN_POLY : REACT_APP_CONTRACT_TOKEN_POLY,
            isTesting ? REACT_APP_TEST_CONTRACT_STAKING_POLY : REACT_APP_CONTRACT_STAKING_POLY,
        ], // list of contract address you want to enable gasless on
    });
}

export function getProvider () {
    return new ethers.providers.Web3Provider(window.ethereum, "any")
}

export async function connectWallet () {
    console.log("Connecting wallet 1");
    const provider = getProvider();
    await provider.send('eth_requestAccounts', []);
    const signer = await provider.getSigner();
    const signerAddress = await signer.getAddress();
    return {address: signerAddress};
}

export async function signMessage () {
    const provider = getProvider();
    const signer = await provider.getSigner();
    const signerAddress = await signer.getAddress();
    return await signer.signMessage(signature_message + signerAddress);
}

export async function changeNetwork (providerType: ProviderType) {
    console.log("Changing network");
    const chainId = providerType === ProviderType.eth ?
        isTesting ? REACT_APP_TEST_NETWORK_ID_ETH.toString() : REACT_APP_NETWORK_ID_ETH.toString() :
        isTesting ? REACT_APP_TEST_NETWORK_ID_POLY.toString() : REACT_APP_NETWORK_ID_POLY.toString();
    if (window.ethereum && window.ethereum.networkVersion !== chainId) {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: utils.hexValue(parseInt(chainId))}], // chainId must be in hexadecimal numbers
            });
        } catch (error) {
            console.log(error);
            throw new Error("Please change your network to " + ProviderType[providerType] + " in your wallet");
        }
    }
}

export async function runTransactionAndWaitForReceipt (transaction: Promise<ethers.ContractTransaction>) {
    const tx = await transaction;

    return await new Promise(async (resolve) => {
        const timeout = setTimeout(async () => {
            let receipt = null;
            let tries = 1;
            while (receipt === null) {
                try {
                    receipt = await getProvider().getTransactionReceipt(tx.hash);

                    if (receipt === null) {
                        console.log(`Trying again to fetch txn receipt, attempt ${tries}`);
                        tries++;
                        if (tries > 10) {
                            throw new Error(`Transaction failed: ${tx.hash}`);
                        }

                        await new Promise(resolve => setTimeout(resolve, 1000));
                        continue;
                    }
                    console.log(`Receipt confirmations using fallback technique:`, receipt.confirmations);
                    resolve(tx);
                } catch (e) {
                    console.log(`Receipt error:`, e);
                    throw e;
                }
            }
        }, 20000);

        await tx.wait();
        clearTimeout(timeout);
        resolve(tx);
    });
}


interface ContractData {
    address: string,
    abi: ContractInterface,
    chain: string
}

export function getContractData(contract : ContractType) : ContractData {
    switch (contract) {
        case ContractType.BridgeEth:
            return {
                address: (isTesting ? REACT_APP_TEST_CONTRACT_BRIDGE_ETH : REACT_APP_CONTRACT_BRIDGE_ETH) || "",
                abi: bridgeEthAbi,
                chain: "eth"
            }
        case ContractType.BridgePoly:
            return {
                address: (isTesting ? REACT_APP_TEST_CONTRACT_BRIDGE_POLY : REACT_APP_CONTRACT_BRIDGE_POLY) || "",
                abi: bridgePolyAbi,
                chain: "poly"
            }
        case ContractType.Game:
            return {
                address: (isTesting ? REACT_APP_TEST_CONTRACT_GAME : REACT_APP_CONTRACT_GAME) || "",
                abi: gameAbi,
                chain: "poly"
            }
        case ContractType.StakingEth:
            return {
                address: (isTesting ? REACT_APP_TEST_CONTRACT_STAKING_ETH : REACT_APP_CONTRACT_STAKING_ETH) || "",
                abi: stakingEthAbi,
                chain: "eth"
            }
        case ContractType.StakingPoly:
            return {
                address: (isTesting ? REACT_APP_TEST_CONTRACT_STAKING_POLY : REACT_APP_CONTRACT_STAKING_POLY) || "",
                abi: stakingPolyAbi,
                chain: "poly"
            }
        case ContractType.TokenEth:
            return {
                address: (isTesting ? REACT_APP_TEST_CONTRACT_TOKEN_ETH : REACT_APP_CONTRACT_TOKEN_ETH) || "",
                abi: tokenEthAbi,
                chain: "eth"
            }
        case ContractType.TokenPoly:
            return {
                address: (isTesting ? REACT_APP_TEST_CONTRACT_TOKEN_POLY : REACT_APP_CONTRACT_TOKEN_POLY) || "",
                abi: tokenPolyAbi,
                chain: "poly"
            }
        case ContractType.WildCard:
            return {
                address: (isTesting ? REACT_APP_TEST_CONTRACT_WILDCARD : REACT_APP_CONTRACT_WILDCARD) || "",
                abi: wildcardAbi,
                chain: "poly"
            }
        case ContractType.ERC20Token:
            return {
                address: (isTesting ? REACT_APP_TEST_CONTRACT_ERC20_TOKEN : REACT_APP_CONTRACT_ERC20_TOKEN) || "",
                abi: erc20Abi,
                chain: "poly"
            }
    }
}