import {NFT, StakingNFT} from "./structs";
import {ContractType, getContractData} from "./web3";
import {FetchWrapper} from "use-nft";
import {BigNumber, Contract} from "ethers";

export async function loadNFT(wrapper: FetchWrapper, tokenId: BigNumber | number, contract: ContractType, isMobile: boolean) : Promise<NFT> {
    let data : {name: string, image: string, description: string, base64?: string} = {
        name: "",
        image: "",
        description: ""
    }
    try {
        console.log("Loading NFT: ", tokenId)
        const contractData = getContractData(contract);
        data = await wrapper.fetchNft(
            contractData.address,
            tokenId instanceof BigNumber ? (tokenId as BigNumber).toNumber().toString() : tokenId.toString()
        )
        if (isMobile) data.base64 = `awaitingLoad|||${tokenId}|||${data.image}`;
        console.log("Loaded NFT: ", data)
    } catch (e) {
        console.log("Error loading NFT: ", e)
    }
    return {
        tokenId: tokenId instanceof BigNumber ? (tokenId as BigNumber).toNumber() : (tokenId as number),
        name: data.name,
        image: data.image,
        description: data.description,
        base64: data.base64
    }
}

export async function loadNFTs(wrapper: FetchWrapper, array: BigNumber[], contract: ContractType, isMobile: boolean) : Promise<NFT[]> {
    if(array.length === 0) {
        return [];
    }
    return await Promise.all(array.map(async (tokenId: BigNumber) => {
        return await loadNFT(wrapper, tokenId, contract, isMobile);
    }));
}

export async function loadStakingNFTs(wrapper: FetchWrapper, array: BigNumber[], stakingContract: Contract, contract: ContractType, isMobile: boolean) : Promise<StakingNFT[]> {
    if(array.length === 0) {
        return [];
    }
    return await Promise.all(array.map(async (tokenId: BigNumber) => {
        let nft = await loadNFT(wrapper, tokenId, contract, isMobile);
        let stakedAt = (await stakingContract.tokenStakedAt(tokenId)).toNumber();
        let lastPlayedAt = (await stakingContract.lastPlayedAt(tokenId)).toNumber();
        return {...nft, stakedAt, lastPlayedAt};
    }));
}

const getOriginalBase64 = async (url: string) : Promise<string> => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data as string);
        }
    });
}

export async function getBase64FromUrl (url: string, width: number, height: number) : Promise<string> {
    return new Promise(async (resolve) => {
        // create an off-screen canvas
        const canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d');

        // set its dimension to target size
        canvas.width = width;
        canvas.height = height;

        const img = new Image();
        // img.crossOrigin = "anonymous";
        img.src = await getOriginalBase64(url);

        img.onload = function () {
            // draw source image into the off-screen canvas:
            ctx!.drawImage(img, 0, 0, width, height);
            // encode image to data-uri with base64 version of compressed image
            resolve(canvas.toDataURL().replace(/^data:image\/(png|jpg);base64,/, ''));
        }
        img.onabort = function () {
            console.log("Image load aborted");
        }
        img.onerror = function (e) {
            console.log("Image load error", e);
        }
    });
}