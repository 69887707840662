import {useEffect, useRef, useState} from "react";
import {Unity, useUnityContext} from "react-unity-webgl";
import styles from "./app.module.css";
import {
    changeNetwork,
    connectWallet,
    ContractType,
    getChainedProvider,
    getContract,
    getContractData,
    getReadOnlyContract,
    initBiconomy,
    ProviderType,
    runTransactionAndWaitForReceipt,
    signMessage
} from "../utils/web3";
import {
    LoadNFTCallbackData,
    LoadSessionCallbackData,
    NFT,
    Session,
    SessionCallBackData,
    StakingNFT
} from "../utils/structs";
import {FetchWrapper} from "use-nft";
import {getBase64FromUrl, loadNFT, loadNFTs, loadStakingNFTs} from "../utils/nftLoader";
import axios from 'axios';
import {BigNumber, Contract} from "ethers";
import {getPendingTokens} from "../utils/tokens";
import {UnityArguments} from "react-unity-webgl/distribution/types/unity-arguments";
import ssLogger from "../utils/ssLogger";
import {isMobile} from 'react-device-detect';

if(process.env.REACT_APP_USE_SS_LOGGER === "true") {
    ssLogger();
}

(BigInt.prototype as any).toJSON = function () {
    return this.toString();
};

const getArguments = () : UnityArguments => {
    return {
        dataUrl: "/unitybuild/HouseHaeds.data",
        frameworkUrl: "/unitybuild/HouseHaeds.framework.js",
        codeUrl: "/unitybuild/HouseHaeds.wasm",
        devicePixelRatio: 2,
        matchWebGLToCanvasSize: true,
        webglContextAttributes: {
            preserveDrawingBuffer: true,
        }
    }
}

const App = () => {
    const [address, setAddress] = useState<string>("");

    const [minStakeTimeBeforePlay, setMinStakeTimeBeforePlay] = useState<number>(0);
    const [minTimeBetweenGames, setMinTimeBetweenGames] = useState<number>(0);

    const [biconomy, setBiconomy] = useState<any>(null);

    const canvasRef = useRef(null);

    const {
        unityProvider,
        isLoaded,
        loadingProgression,
        addEventListener,
        removeEventListener,
        sendMessage
    } = useUnityContext({...getArguments(), loaderUrl: "/unitybuild/HouseHaeds.loader.js"});

    const [devicePixelRatio, setDevicePixelRatio] = useState(
        window.devicePixelRatio
    );

    const getSession = async (gameContract: Contract) : Promise<SessionCallBackData> => {
        const sessionProxy = await gameContract.getGameSession();

        const polyFetcher = ["ethers", { provider: getChainedProvider(ProviderType.poly) }]
        // @ts-ignore
        const polyFetchWrapper = new FetchWrapper(polyFetcher);

        // Load Session NFT Data
        const sessionTokens : NFT[] = await loadNFTs(polyFetchWrapper, sessionProxy[1], ContractType.TokenPoly, isMobile);
        const sessionLostToken: NFT = await loadNFT(polyFetchWrapper, sessionProxy[3], ContractType.TokenPoly, isMobile);
        const sessionGainedToken: NFT = await loadNFT(polyFetchWrapper, sessionProxy[4], ContractType.TokenPoly, isMobile);
        const sessionWildcard: NFT = await loadNFT(polyFetchWrapper, sessionProxy[5], ContractType.WildCard, isMobile);
        const sessionUnwantedTokens : NFT[] = await loadNFTs(polyFetchWrapper, sessionProxy[10], ContractType.TokenPoly, isMobile);
        const sessionFinalTokens : NFT[] = await loadNFTs(polyFetchWrapper, sessionProxy[11], ContractType.TokenPoly, isMobile);

        const session : Session = {
            player:  sessionProxy[0],
            tokens: sessionTokens,
            lostTokenIdIndex: sessionProxy[2],
            lostToken: sessionLostToken,
            gainedToken: sessionGainedToken,
            wildCard: sessionWildcard,
            coinsWon: sessionProxy[6],
            drawAgainPrice: sessionProxy[7],
            timestamp: sessionProxy[8],
            _exists: sessionProxy[9],
            unwantedTokens: sessionUnwantedTokens,
            finalTokens: sessionFinalTokens,
        }

        return {
            success: true,
            session
        }
    }


    const handleLoadSession = async () => {
        let data : LoadSessionCallbackData;
        try {
            let _address = address;
            if(_address === "") {
                _address = (await connectWallet()).address
                setAddress(_address);
            }
            await changeNetwork(ProviderType.poly);

            if(!biconomy) {
                const biconomy = initBiconomy();
                setBiconomy(biconomy);
                await biconomy.init();
            }

            // const gameContract = await getReadOnlyContract(ContractType.Game);
            const stakingPolyContract = await getReadOnlyContract(ContractType.StakingPoly);
            const ethTokenContract = await getReadOnlyContract(ContractType.TokenEth);
            const polyTokenContract = await getReadOnlyContract(ContractType.TokenPoly);
            const erc20Contract = await getReadOnlyContract(ContractType.ERC20Token);
            // const sessionProxy = await gameContract.getGameSession(_address);
            const stkPolyNfts = await stakingPolyContract.stakedTokenIdsOf(_address);
            const ethNfts = await ethTokenContract.walletOfOwner(_address);
            const polyNfts = await polyTokenContract.walletOfOwner(_address);
            const erc20Balance = (await erc20Contract.balanceOf(_address)).toNumber();

            const incompleteEthBridges : BigNumber[] = [];
            const incompletePolyBridges : BigNumber[] = [];

            // Load incomplete bridges
            try {
                const { data: incompleteBridges } = await axios.get(process.env.REACT_APP_BRIDGE_SERVER_URL + '/incomplete-bridges', {
                    params: { address: _address }
                });
                if(Array.isArray(incompleteBridges.eth)) {
                    incompleteBridges.eth.forEach((bridge: BigNumber) => {
                        incompleteEthBridges.push(bridge);
                    });
                }
                if(Array.isArray(incompleteBridges.matic)) {
                    incompleteBridges.matic.forEach((bridge: BigNumber) => {
                        incompletePolyBridges.push(bridge);
                    });
                }
            } catch (e) {
                console.error(e)
            }

            const ethFetcher = ["ethers", { provider: getChainedProvider(ProviderType.eth) }]
            // @ts-ignore
            const ethFetchWrapper = new FetchWrapper(ethFetcher);
            const polyFetcher = ["ethers", { provider: getChainedProvider(ProviderType.poly) }]
            // @ts-ignore
            const polyFetchWrapper = new FetchWrapper(polyFetcher);


            const numberOfNfts = ethNfts.length + polyNfts.length + stkPolyNfts.length + incompleteEthBridges.length + incompletePolyBridges.length;
            const loadOneByOne = isMobile || numberOfNfts > 10;
            console.log("loadOneByOne", loadOneByOne);

            // Load Staked Poly NFTs
            const stakedPolyNfts : StakingNFT[] = await loadStakingNFTs(polyFetchWrapper, stkPolyNfts, stakingPolyContract, ContractType.TokenPoly, loadOneByOne);

            // Load Eth NFT Data
            const unstakedEthNfts : NFT[] = await loadNFTs(ethFetchWrapper, ethNfts, ContractType.TokenEth, loadOneByOne);
            const incompleteBridgeEthNfts : NFT[] = await loadNFTs(ethFetchWrapper, incompleteEthBridges, ContractType.TokenEth, loadOneByOne);

            // Load Poly NFT Data
            const unstakedPolyNfts : NFT[] = await loadNFTs(polyFetchWrapper, polyNfts, ContractType.TokenPoly, loadOneByOne);
            const incompleteBridgePolyNfts : NFT[] = await loadNFTs(polyFetchWrapper, incompletePolyBridges, ContractType.TokenPoly, loadOneByOne);

            const sessionTokens : NFT[] = [];
            const sessionLostToken: NFT = {tokenId: 0, name: "", image: "", description: "", base64: ""};
            const sessionGainedToken: NFT = {tokenId: 0, name: "", image: "", description: "", base64: ""};
            const sessionWildcard: NFT = {tokenId: 0, name: "", image: "", description: "", base64: ""};
            const sessionUnwantedTokens : NFT[] = [];
            const sessionFinalTokens : NFT[] = [];

            const pendingTokens = await getPendingTokens(stakingPolyContract, stkPolyNfts);

            const session : Session = {
                player:  _address,
                tokens: sessionTokens,
                lostTokenIdIndex: 0,
                lostToken: sessionLostToken,
                gainedToken: sessionGainedToken,
                wildCard: sessionWildcard,
                coinsWon: 0,
                drawAgainPrice: 0,
                timestamp: 0,
                _exists: false,
                unwantedTokens: sessionUnwantedTokens,
                finalTokens: sessionFinalTokens,
            }

            data = {
                success: true,
                stakedPolyNfts,
                unstakedEthNfts,
                unstakedPolyNfts,
                incompleteBridgeEthNfts,
                incompleteBridgePolyNfts,
                accumulatedTokens: erc20Balance,
                pendingTokens,
                session,
                minStakeTimeBeforePlay,
                minTimeBetweenGames
            }
        } catch (e: any) {
            console.error(e);
            data = {
                success: false,
                message: e.reason ?? e.message
            }
        }
        console.log(data, JSON.stringify(data));
        sendMessage("GameManager", "LoadSessionCb", JSON.stringify(data));
    }

    const handleStartSession = async (nfts: string, wildcard: string) => {
        let data : SessionCallBackData;
        try {
            let _address = address;
            if(_address === "") {
                _address = (await connectWallet()).address
                setAddress(_address);
            }
            await changeNetwork(ProviderType.poly);
            const gameContract = await getContract(ContractType.Game);

            let parsedNfts : number[] = JSON.parse(nfts);
            let parsedWildcard : number = wildcard ? parseInt(wildcard) : 0;

            const tx = await gameContract.startSession(parsedNfts, parsedWildcard);
            await tx.wait();

            data = await getSession(gameContract);
        } catch (e: any) {
            console.error(e);
            data = {
                success: false,
                message: e.reason ?? e.message
            }
        }
        sendMessage("GameManager", "StartSessionCb", JSON.stringify(data));
    }

    const handleSurrender = async () => {
        let data : SessionCallBackData;
        try {
            let _address = address;
            if(_address === "") {
                _address = (await connectWallet()).address
                setAddress(_address);
            }
            await changeNetwork(ProviderType.poly);
            const gameContract = await getContract(ContractType.Game);

            const tx = await gameContract.surrender();
            await tx.wait();

            data = await getSession(gameContract);
        } catch (e: any) {
            console.error(e);
            data = {
                success: false,
                message: e.reaon ?? e.message
            }
        }
        sendMessage("GameManager", "SurrenderCb", JSON.stringify(data));
    }

    const handleRedraw = async () => {
        let data : SessionCallBackData;
        try {
            let _address = address;
            if(_address === "") {
                _address = (await connectWallet()).address
                setAddress(_address);
            }
            await changeNetwork(ProviderType.poly);
            const gameContract = await getContract(ContractType.Game);

            const tx = await gameContract.drawAgain();
            await tx.wait();

            data = await getSession(gameContract);
        } catch (e: any) {
            console.error(e);
            data = {
                success: false,
                message: e.reason ?? e.message
            }
        }
        sendMessage("GameManager", "RedrawCb", JSON.stringify(data));
    }

    const handleEndSession = async () => {
        let data : SessionCallBackData;
        try {
            let _address = address;
            if(_address === "") {
                _address = (await connectWallet()).address
                setAddress(_address);
            }
            await changeNetwork(ProviderType.poly);
            const gameContract = await getContract(ContractType.Game);

            const tx = await gameContract.finishSession();
            await tx.wait();

            data = await getSession(gameContract);
        } catch (e: any) {
            console.error(e);
            data = {
                success: false,
                message: e.reason ?? e.message
            }
        }
        console.log(data);
        sendMessage("GameManager", "EndSessionCb", JSON.stringify(data));
    }

    const handleBridgeNFT = async (tokenIds: string, fromChain: string) => {
        try {
            let _address = address;
            if(_address === "") {
                _address = (await connectWallet()).address
                setAddress(_address);
            }
            await changeNetwork(fromChain === "eth" ? ProviderType.eth : ProviderType.poly);

            const bridgeEth = await getContract(ContractType.BridgeEth);
            const bridgePoly = await getContract(ContractType.BridgePoly, biconomy);

            let parsedNfts : number[] = JSON.parse(tokenIds);

            console.log(parsedNfts)

            try {
                const tokenContract = await getContract(fromChain === "eth" ? ContractType.TokenEth : ContractType.TokenPoly, fromChain === "eth" ? undefined : biconomy);
                const tokenContractReadOnly = await getReadOnlyContract(fromChain === "eth" ? ContractType.TokenEth : ContractType.TokenPoly);
                let isApprovedForAll = await tokenContractReadOnly.isApprovedForAll(_address, getContractData(fromChain === "eth" ? ContractType.BridgeEth : ContractType.BridgePoly).address);
                if (!isApprovedForAll) {
                    if(fromChain === "eth") {
                        const estimatedGas = await tokenContract.estimateGas.setApprovalForAll(getContractData(ContractType.BridgePoly).address, true);
                        await runTransactionAndWaitForReceipt(tokenContract.setApprovalForAll(getContractData(ContractType.BridgeEth).address, true, {
                            gasLimit: estimatedGas.toNumber() > 90000 ? estimatedGas.mul(130).div(100) : 200000,
                        }));
                        // await (await tokenContract.setApprovalForAll(getContractData(ContractType.BridgeEth).address, true)).wait();
                    } else {
                        console.log("Approving for all using biconomy");
                        await sendBiconomyTransaction(tokenContract, address, "setApprovalForAll", [getContractData(ContractType.BridgePoly).address, true]);
                        console.log("Approved for all using biconomy");
                    }
                    await new Promise((r) => setTimeout(r, 500));
                }
                if (fromChain === "eth") {
                    const estimatedGas = await bridgeEth.estimateGas.bridgeMultiple(parsedNfts);
                    await runTransactionAndWaitForReceipt(bridgeEth.bridgeMultiple(parsedNfts, {
                        gasLimit: estimatedGas.toNumber() > 90000 ? estimatedGas.mul(130).div(100) : 200000,
                    }));
                    // await (await bridgeEth.bridgeMultiple(parsedNfts)).wait();
                } else {
                    await sendBiconomyTransaction(bridgePoly, address, "unstakeAndBridgeToEthMultiple", [parsedNfts]);
                }
            } catch (e: any) {
                if(e.reason === "rejected" || e.message === "MetaMask Tx Signature: User denied transaction signature.") {
                    throw new Error("User rejected transaction");
                }
                console.error(e);
            }

            const signature = await signMessage();
            const { data: result } = await axios.post(process.env.REACT_APP_BRIDGE_SERVER_URL + '/release', {
                address: _address,
                fromChain: fromChain === "eth" ? 'eth' : 'matic',
                signature: signature,
                toChain: fromChain === "eth" ? 'matic' : 'eth',
                tokenIds: parsedNfts
            });
            if(!result.coupons || !result.nonces) {
                throw new Error("No Bridging was found on the server. Please contact support");
            }

            await changeNetwork(fromChain === "eth" ? ProviderType.poly : ProviderType.eth);

            if (result) {
                if (fromChain === "eth") {
                    await sendBiconomyTransaction(bridgePoly, address, "releaseToPolyAndStakeMultiple", [parsedNfts, result.nonces, result.coupons]);
                } else {
                    const estimatedGas = await bridgeEth.estimateGas.releaseMultiple(parsedNfts, result.nonces, result.coupons)
                    console.log("gas limit", estimatedGas.toNumber() > 90000 ? estimatedGas.mul(130).div(100) : 200000);

                    await runTransactionAndWaitForReceipt(bridgeEth.releaseMultiple(parsedNfts, result.nonces, result.coupons, {
                        gasLimit: estimatedGas.toNumber() > 90000 ? estimatedGas.mul(130).div(100) : 200000,
                    }));
                    // await (await bridgeEth.releaseMultiple(parsedNfts, result.nonces, result.coupons)).wait();
                }
            }
            await handleLoadSession();
        } catch (e: any) {
            console.log(e);
            let data: LoadSessionCallbackData = {
                success: false,
                message: e.reason ?? e.message
            }
            sendMessage("GameManager", "LoadSessionCb", JSON.stringify(data));
        }
    }

    const handleStakeNFT = async (tokenIds: string, chain: string) => {
        await handleBridgeNFT(tokenIds, chain === "eth" ? "matic" : "eth");
        // try {
        //     let _address = address;
        //     if(_address === "") {
        //         _address = (await connectWallet()).address
        //         setAddress(_address);
        //     }
        //     await changeNetwork(chain === "eth" ? ProviderType.eth : ProviderType.poly);
        //
        //     const tokenContract = await getContract(chain === "eth" ? ContractType.TokenEth : ContractType.TokenPoly);
        //     const stakingContract = await getContract(chain === "eth" ? ContractType.StakingEth : ContractType.StakingPoly);
        //
        //     const stakingContractAddress = getContractData(chain === "eth" ? ContractType.StakingEth : ContractType.StakingPoly).address;
        //     let isApprovedForAll = await tokenContract.isApprovedForAll(_address, stakingContractAddress);
        //     if (!isApprovedForAll) {
        //         await (await tokenContract.setApprovalForAll(stakingContractAddress, true)).wait();
        //         await new Promise((r) => setTimeout(r, 500));
        //     }
        //
        //     let parsedNfts : number[] = JSON.parse(tokenIds);
        //     await (await stakingContract.stake(parsedNfts)).wait();
        //     await handleLoadSession();
        // } catch (e: any) {
        //     let data: LoadSessionCallbackData = {
        //         success: false,
        //         message: e.reason ?? e.message
        //     }
        //     sendMessage("GameManager", "LoadSessionCb", JSON.stringify(data));
        // }
    }

    const handleUnstakeNFT = async (tokenIds: string, chain: string) => {
        await handleBridgeNFT(tokenIds, chain);
        // try {
        //     let _address = address;
        //     if(_address === "") {
        //         _address = (await connectWallet()).address
        //         setAddress(_address);
        //     }
        //     await changeNetwork(chain === "eth" ? ProviderType.eth : ProviderType.poly);
        //
        //     const stakingContract = await getContract(chain === "eth" ? ContractType.StakingEth : ContractType.StakingPoly);
        //
        //     let parsedNfts : number[] = JSON.parse(tokenIds);
        //     await (await stakingContract.unstake(parsedNfts)).wait();
        //     await handleLoadSession();
        // } catch (e: any) {
        //     let data: LoadSessionCallbackData = {
        //         success: false,
        //         message: e.reason ?? e.message
        //     }
        //     sendMessage("GameManager", "LoadSessionCb", JSON.stringify(data));
        // }
    }

    const handleWithdraw = async () => {
        try {
            let _address = address;
            if(_address === "") {
                _address = (await connectWallet()).address
                setAddress(_address);
            }
            await changeNetwork(ProviderType.poly);

            const stakingContract = await getContract(ContractType.StakingPoly, biconomy);
            await sendBiconomyTransaction(stakingContract, address, "withdrawWithoutUnstaking", []);

            await handleLoadSession();
        } catch (e: any) {
            console.log(e);
            let data: LoadSessionCallbackData = {
                success: false,
                message: e.reason ?? e.message
            }
            sendMessage("GameManager", "LoadSessionCb", JSON.stringify(data));
        }
    }

    const handleLoadNFT = async (awaitingLoadString: string) => {
        try {
            console.log(awaitingLoadString)
            const split = awaitingLoadString.split("|||");
            const tokenId = parseInt(split[1]);
            const url = split[2];

            const base64 = await getBase64FromUrl(url, 512, 512);

            let data: LoadNFTCallbackData = {
                success: true,
                tokenId: tokenId,
                base64: base64
            }
            console.log(data)
            sendMessage("GameManager", "LoadNFTCb", JSON.stringify(data));
        } catch (e: any) {
            console.log(e);
            let data: LoadNFTCallbackData = {
                success: false,
                message: e.reason ?? e.message
            }
            sendMessage("GameManager", "LoadNFTCb", JSON.stringify(data));
        }
    }


    const sendBiconomyTransaction = (contract: Contract, userAddress: string, functionName: string, arg: any = undefined) => {
        return new Promise<string>(async (resolve, reject) => {
            if (contract) {
                try {
                    let {data} = await contract.populateTransaction[functionName](...arg);
                    let provider = biconomy.provider;
                    let txParams = {
                        data: data,
                        to: contract.address,
                        from: userAddress,
                        signatureType: "EIP712_SIGN",
                        gasLimit: 1000000,
                    };
                    try {
                        let tx = await provider.send("eth_sendTransaction", [txParams])
                        console.log("Transaction ID : ", tx.transactionId);
                        if(!tx.transactionId) {
                            reject("Transaction ID not found");
                        }

                        biconomy.clientMessenger.createTransactionNotifier(tx.transactionId, {
                            onMined: (tx: { transactionId: string; transactionHash: string; receipt: any }) => {
                                console.log("Transaction mined");
                                resolve(tx.transactionHash);
                            },
                            onError: (errorResponseData: { error: any; transactionId: string; }) => {
                                console.log("Transaction failed");
                                reject(errorResponseData.error);
                            }
                        });

                        setTimeout(() => {
                            reject("Transaction not mined after 10min");
                        }, 600000);
                    } catch (err) {
                        console.log("handle errors like signature denied here");
                        console.log(err);
                        reject(err);
                    }

                } catch (error) {
                    console.log(error);
                    reject(error);
                }
            } else {
                reject("Contract not found");
            }
        });
    };

    useEffect(() => {
        addEventListener("LoadSession", handleLoadSession as any);
        addEventListener("StartSession", handleStartSession as any);
        addEventListener("Surrender", handleSurrender as any);
        addEventListener("Redraw", handleRedraw as any);
        addEventListener("EndSession", handleEndSession as any);
        addEventListener("BridgeNFT", handleBridgeNFT as any);
        addEventListener("StakeNFT", handleStakeNFT as any);
        addEventListener("UnstakeNFT", handleUnstakeNFT as any);
        addEventListener("Withdraw", handleWithdraw as any);
        addEventListener("LoadNFT", handleLoadNFT as any);

        return () => {
            removeEventListener("LoadSession", handleLoadSession as any);
            removeEventListener("StartSession", handleStartSession as any);
            removeEventListener("Surrender", handleSurrender as any);
            removeEventListener("Redraw", handleRedraw as any);
            removeEventListener("EndSession", handleEndSession as any);
            removeEventListener("BridgeNFT", handleBridgeNFT as any);
            removeEventListener("StakeNFT", handleStakeNFT as any);
            removeEventListener("UnstakeNFT", handleUnstakeNFT as any);
            removeEventListener("Withdraw", handleWithdraw as any);
            removeEventListener("LoadNFT", handleLoadNFT as any);
        };
    }, [handleLoadSession, handleStartSession, handleSurrender, handleRedraw, handleEndSession, handleBridgeNFT, handleStakeNFT, handleUnstakeNFT, handleWithdraw, handleLoadNFT, addEventListener, removeEventListener]);

    useEffect(() => {
        const updateDevicePixelRatio = function () {
            setDevicePixelRatio(window.devicePixelRatio);
        };
        const mediaMatcher = window.matchMedia(
            `screen and (resolution: ${devicePixelRatio}dppx)`
        );
        mediaMatcher.addEventListener("change", updateDevicePixelRatio);
        return function () {
            mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
        };
    }, [devicePixelRatio]);

    return (
        <div className={styles.container}>
            <div className={styles.unityWrapper}>
                {!isLoaded && (
                    <div className={styles.loadingBar}>
                        <div
                            className={styles.loadingBarFill}
                            style={{width: loadingProgression * 100}}
                        />
                    </div>
                )}
                <Unity
                    ref={canvasRef}
                    unityProvider={unityProvider}
                    style={{display: isLoaded ? "block" : "none"}}
                    devicePixelRatio={devicePixelRatio}
                />
            </div>
        </div>
    );
};

export {App};
